import request from "@/utils/request";

    /**
     *
     * @param data
     * @returns {AxiosPromise}
     */
    export function getTradeMrrkShare(data) {
        return request({
            url: process.env.VUE_APP_BASE_API +'/trademark/service/getTradeMrrkShare',
            method: 'post',
            data
        })
    }


/**
*
* @param data
* @returns {AxiosPromise}
*/
    export function checkNumber(data) {
        return request({
            url: process.env.VUE_APP_BASE_API +'/user/service/getMobileInUse',
            method: 'post',
            data
        })
    }


/**
*
* @param data
* @returns {AxiosPromise}
*/
    export function sendSmsMsg(data) {
        return request({
            url: process.env.VUE_APP_BASE_API +'/msg/smsMsg/sendSmsMsg',
            method: 'post',
            data
        })
    }


/**
*
* @param data
* @returns {AxiosPromise}
*/
    export function register(data) {
        return request({
            url: process.env.VUE_APP_BASE_API +'/user/service/newPutUserLogin',
            method: 'post',
            data
        })
    }



/**
*
* @param data
* @returns {AxiosPromise}
*/
    export function joinCityProxy(data) {
        return request({
            url: process.env.VUE_APP_BASE_API +'/user/service/joinCityProxy',
            method: 'post',
            data
        })
    }

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getIndustry(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/trademark/service/ai/getTrademarkRecommendData',
        method: 'post',
        data
    })
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getRate(data) {
    return request({
        url: 'https://aipro.guolaoai.com/private/rate',
        method: 'post',
        data
    })
}

export function queryRandomIssuingRate(data = {}) {
    return request({
        url:  process.env.VUE_APP_BASE_API + '/trademark/service/ai/queryRandomIssuingRate',
        method: 'post',
        data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getMonitorCompany(data) {
    return request({
        url: 'https://aipro.guolaoai.com/search/knowledgeMonitorCompany',
        method: 'post',
        data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getUserTrademarkCount(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/trademark/service/ai/getUserKnowledgeMainTrademarkCount',
        method: 'post',
        data
    })
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getIntClsCount(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/trademark/service/ai/getIntClsCount',
        method: 'post',
        data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getComFuzzy(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/user/service/getComFuzzy',
        method: 'post',
        data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getTrademarkList(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/trademark/service/ai/knowledgeAllZhangYe',
        method: 'post',
        data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function signLogin(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/auth/signLogin',
        method: 'post',
        data
    })
}


/**
 *
 * @param data {
 *     keyword
          page
          size
 * }
 * @returns {AxiosPromise}
 */
export function suggest(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/trademark/service/es/trademark/regiest/search/completion/suggest',
        method: 'post',
        data
    })
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function putContactConsult(data) {
	return request({
		url: process.env.VUE_APP_BASE_API + '/user/service/contactConsult',
		method: 'post',
		data
	})
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getCompanySelect(data) {
	return request({
		url: 'https://aipro.guolaoai.com/search/knowledgeMonitorCompanySelect',
		method: 'post',
		data
	})
}


/**
 * @title 敏感词
 * @param data
 * @returns {AxiosPromise}
 */
export function forbid(data) {
	return request({
		url: process.env.VUE_APP_BASE_API +'/trademark/service/ai/forbid',
		method: 'post',
		data
	})
}

/**
 * @title 记录日志
 * @param data
 * @returns {AxiosPromise}
 */
export function insertClickLog(data) {
	return request({
		url: process.env.VUE_APP_BASE_API +'/user/service/clicklog/insertClickLogNotIP',
		method: 'post',
		data
	})
}
