<template>
  <div style="width:100%;">
    <!--                一类-->
    <div class="context-list-category-a">
      <div>
        <span>{{ Number(data.num) > 9 ? data.num : '0' + data.num }}类</span>
        <span style="margin-left:5px;">{{ data.name }}</span>
        <span class="tab">{{ data.trandemarkStatus }}</span>
      </div>
      <div>
        <Icon
            @click="foldHandle"
            name="arrow-up"
            class="icon-fold"
            :style="{'transform': fold ? 'rotate(180deg)' : 'rotate(0deg)'}"></Icon>
      </div>
    </div>
    <!--                二类-->
    <div v-for="item in data.sec" v-show="!fold">
      <TwoCategory :data="item"></TwoCategory>
    </div>
  </div>
</template>

<script>
import {Icon} from 'vant'
import TwoCategory from "@/views/fbw/components/TwoCategory";

export default {
  name: "TheBrandCategory",
  data() {
    return {
      fold: false,
      star: require('@/assets/star.png'),
      starActive: require('@/assets/star_active.png')
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    foldHandle() {
      this.fold = !this.fold;
    }
  },
  components: {TwoCategory, Icon}
}
</script>

<style scoped>
.context-list-category-a {
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  margin: 14px 0 10px;
  font-size: 14px;
}

.tab {
  font-size: 12px;
  color: #666;
  margin-left: 5px;
}

.icon-fold {
  color: #666;
  transition: all ease .3s;
}

</style>